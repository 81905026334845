import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/DangerBox';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud - License Plate Recognition",
  "path": "/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/",
  "dateChanged": "2021-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Here you can see all the people captured in your recorded videos. Each person is displayed with their name and a thumbnail of their face.",
  "image": "./MD_SearchThumb_Cloud_Recordings.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Recordings.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR Cloud - License Plate Recognition' dateChanged='2021-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can see all the recorded number plates in your recorded alarm videos. A picture cut out of the video is displayed for each licence plate, as well as the licence plate that the Cloud has read from it.' image='/images/Search/MD_SearchThumb_Cloud_Recordings.png' twitter='/images/Search/MD_SearchThumb_Cloud_Recordings.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Nummernschilderkennung/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/' crumbLabel="License Plate" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h1>
    <h2 {...{
      "id": "license-plate-recognition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#license-plate-recognition",
        "aria-label": "license plate recognition permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`License Plate Recognition`}</h2>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#license-plate-recognition"
        }}>{`License Plate Recognition`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-the-service"
            }}>{`Activate the Service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#last-recorded"
            }}>{`Last Recorded`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#top-10-sighted"
            }}>{`Top 10 Sighted`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#number-plate-register"
            }}>{`Number Plate Register`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#advanced-options"
            }}>{`Advanced Options`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "activate-the-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activate-the-service",
        "aria-label": "activate the service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activate the Service`}</h3>
    <p>{`To use number plate recognition or person recognition, the respective icon on the camera must be activated (orange).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5a0ec3c37297d5dce055abc0becf984/ae77d/License_Plate_Recognition_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxklEQVQoz4WSTWtTQRSG709Qf4Zdd13/RZaBbLIRxEB10Y2LRkEjSP6CG8FGss4uQkGKSkm1tjEY2zQ3uSa5H3Pnfs69eWRGUtSFDhzO8HJ45p1zjjX0U079jKknuXBW2EsPx3EIw9DkxWLBcrlkNpvhuq65z+dzo8dxjFKKPM+vw3o1itn/KHn0QXLvUPDiJEKGgiAIWK/X6KNzWZYm/y+sr1cOR2djBmOb0Q/BxJVkWWZeLooCKSVJkhiw1jZRFiUq/+Xud91KIkkcBiRSEAlf+6HX61Gr1ahWq1QqFer1OoPBgNVqZR7Ls5xL7wIvcQ1Ya0bXX5ZRRCAEQoSsXNc4aTabWJZFo9Fge3vb3LvdruljFEXG+fDbkOOTY2zbNs6ugbpACGGG4HmeAbbbbba2btNqtej3+9zZ2eH1wQG+75s6DbSnNleTCVKGBpb+C/is9ZwbN28ZZ5vodDpmyrqnGzeqhLyAIs9BZRTqL6B2UBQl7w7fsnv/Lk/393iyt8vjBw85Oz83K6PrNSxNM9JgTrYc4viCsZ/hx9mfQJ1lFEPmooIJpCOYfoZPp+gFUpt90w5ViZq9Z/3lJUffJ7wZ54y8lJ/wIIJhMIZV6wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/e4706/License_Plate_Recognition_05.avif 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/d1af7/License_Plate_Recognition_05.avif 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/7f308/License_Plate_Recognition_05.avif 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/24329/License_Plate_Recognition_05.avif 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/a0b58/License_Plate_Recognition_05.webp 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/bc10c/License_Plate_Recognition_05.webp 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/966d8/License_Plate_Recognition_05.webp 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/e4604/License_Plate_Recognition_05.webp 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/81c8e/License_Plate_Recognition_05.png 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/08a84/License_Plate_Recognition_05.png 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/c0255/License_Plate_Recognition_05.png 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/ae77d/License_Plate_Recognition_05.png 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5a0ec3c37297d5dce055abc0becf984/c0255/License_Plate_Recognition_05.png",
              "alt": "INSTAR Cloud - License Plate Recognition",
              "title": "INSTAR Cloud - License Plate Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/*
     import PrimaryBox from 'components/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/PrimaryBox'
     <PrimaryBox />
     */}
    <h3 {...{
      "id": "last-recorded",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#last-recorded",
        "aria-label": "last recorded permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Last Recorded`}</h3>
    <p>{`Here you can see all the recorded number plates in your recorded alarm videos. A picture cut out of the video is displayed for each licence plate, as well as the licence plate that the Cloud has read from it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e6811bfbdd06c5513316f71041362463/077b7/License_Plate_Recognition_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGklEQVQY012QzU6DQBSFeXHjI7jxEXwAu3RnjG13pqVNtLSpFQMUBgbKzzAzUD5TjF14kpPv7u45x8lTgdWaMpfopiZNU+qqojQdcWMpTI9IEqSUFEVBnucIkZBJSadqfjVc6dzOUu6XFTezjLu3grbVVFWFulC1NK2mVYq6rtFaY63FGIM1mmWsmIaGeaSZhpqT7nAe3YDJ4sBkFTL1S4bh7xsM5/P17vue/1oniud9zswvefEbKjvgpKFP9LUn/j5g6tOYzA+OJHE8JomiaKwchiFKqZFZmo3Vm5OkLiTn3oKW0Gmc7W7Hxtvy/rEhzyWJbHia71ivXDxvi+u6oy8blmWJEALf9wmCgOQY8XmUvO5C7OKBTnj8AOtMd2v04qukAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6811bfbdd06c5513316f71041362463/e4706/License_Plate_Recognition_01.avif 230w", "/en/static/e6811bfbdd06c5513316f71041362463/d1af7/License_Plate_Recognition_01.avif 460w", "/en/static/e6811bfbdd06c5513316f71041362463/7f308/License_Plate_Recognition_01.avif 920w", "/en/static/e6811bfbdd06c5513316f71041362463/caf2c/License_Plate_Recognition_01.avif 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e6811bfbdd06c5513316f71041362463/a0b58/License_Plate_Recognition_01.webp 230w", "/en/static/e6811bfbdd06c5513316f71041362463/bc10c/License_Plate_Recognition_01.webp 460w", "/en/static/e6811bfbdd06c5513316f71041362463/966d8/License_Plate_Recognition_01.webp 920w", "/en/static/e6811bfbdd06c5513316f71041362463/308c4/License_Plate_Recognition_01.webp 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e6811bfbdd06c5513316f71041362463/81c8e/License_Plate_Recognition_01.png 230w", "/en/static/e6811bfbdd06c5513316f71041362463/08a84/License_Plate_Recognition_01.png 460w", "/en/static/e6811bfbdd06c5513316f71041362463/c0255/License_Plate_Recognition_01.png 920w", "/en/static/e6811bfbdd06c5513316f71041362463/077b7/License_Plate_Recognition_01.png 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e6811bfbdd06c5513316f71041362463/c0255/License_Plate_Recognition_01.png",
              "alt": "INSTAR Cloud - License Plate Recognition",
              "title": "INSTAR Cloud - License Plate Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "top-10-sighted",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#top-10-sighted",
        "aria-label": "top 10 sighted permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Top 10 Sighted`}</h3>
    <p>{`Here you get an overview of the most frequently sighted number plates.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce1db41565b847b5a43119a689beda98/0a867/License_Plate_Recognition_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABbElEQVQY0x3Ly27TQBSAYT80W14AtohFhYBlKGxKK7EAqRKlaeNEqpIorRrHJmPHFzJxx3M7c+Y4tpHy69v+QVFVDr3Shtcv2piyLJWUxreFocqSBMzzvH6p9/u9d1DuUpBC1ZzQDcMQ2EOpeQmCd7bpCQDAWuuJDHrlvPOEiM45jxgLv6js8p+dl/qRO3/sg3eT4v04PQvzDwvxI1aEAEY1QoC1ALYRoq5ronbou6vEjiJ3ucXvW3/+99hgF7z+c3gT1m/Dw6vr6uNKe8SHRxZFEeccHSql+J5LKbeMOS371le2yzV1Ku07CnIWb56fNs9P22jdoZW2Pb+Ow+nk980NS9Ou78E5JNIA1LbU0peN+7RSZvF18DpI0+yEpWnmwAphvv2KppO78P6eJQkaY6VEIZosI7BkzM/EjFYNzE+zUkqfkkoh2IYVo4v57PZ2Nh6z5dIlsc+y43rtZ9PjbkeMDUVxMa/k3efByf+ifnr/hORJ0gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1db41565b847b5a43119a689beda98/e4706/License_Plate_Recognition_02.avif 230w", "/en/static/ce1db41565b847b5a43119a689beda98/d1af7/License_Plate_Recognition_02.avif 460w", "/en/static/ce1db41565b847b5a43119a689beda98/7f308/License_Plate_Recognition_02.avif 920w", "/en/static/ce1db41565b847b5a43119a689beda98/c0669/License_Plate_Recognition_02.avif 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce1db41565b847b5a43119a689beda98/a0b58/License_Plate_Recognition_02.webp 230w", "/en/static/ce1db41565b847b5a43119a689beda98/bc10c/License_Plate_Recognition_02.webp 460w", "/en/static/ce1db41565b847b5a43119a689beda98/966d8/License_Plate_Recognition_02.webp 920w", "/en/static/ce1db41565b847b5a43119a689beda98/b508b/License_Plate_Recognition_02.webp 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1db41565b847b5a43119a689beda98/81c8e/License_Plate_Recognition_02.png 230w", "/en/static/ce1db41565b847b5a43119a689beda98/08a84/License_Plate_Recognition_02.png 460w", "/en/static/ce1db41565b847b5a43119a689beda98/c0255/License_Plate_Recognition_02.png 920w", "/en/static/ce1db41565b847b5a43119a689beda98/0a867/License_Plate_Recognition_02.png 986w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce1db41565b847b5a43119a689beda98/c0255/License_Plate_Recognition_02.png",
              "alt": "INSTAR Cloud - License Plate Recognition",
              "title": "INSTAR Cloud - License Plate Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "number-plate-register",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#number-plate-register",
        "aria-label": "number plate register permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Number Plate Register`}</h3>
    <p>{`Here you can assign a holder to each licence plate and edit the relationship to it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/2e367/License_Plate_Recognition_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABK0lEQVQY0zXOS07DMBSF4WybAXPYAAtA4rUAhBASAygtbSRoBaraQpog6jpObDdOYsdvQ4r4Rmdwf+lGAAApJSGE1TXGOM9z3nVI2A3XlegAAAghXPYghAVCeFf7EHzw3vuoLEsAAKVUCGH2pFJKSWu0NcY5F/b8Xr/oR1jfhc2zMzpK18lqtUyShFU7a/TfkXXOGNvHf8E/LmT7PROLG54OvbPRwQAfT8jRGB8O6RzLpqIIFdZazjmE0CiVb0DTtoyxAhVN22rnjQ+2/9tFp3F2HqcXcXo125LOXA/Sp1E8nb5+ZVndNpJiupjzTu4o3ULISDktzfLtIbyfWd1FHSOiIrwisqYh2Mv77HE4jieTLMuMMkyxpPp0zhmtGatVw0bIzuLb8HLyG/8A0TBLR/PHCuQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ac544a551b765fa6dc5d56d496ca5c1/e4706/License_Plate_Recognition_03.avif 230w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/d1af7/License_Plate_Recognition_03.avif 460w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/7f308/License_Plate_Recognition_03.avif 920w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/730d0/License_Plate_Recognition_03.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ac544a551b765fa6dc5d56d496ca5c1/a0b58/License_Plate_Recognition_03.webp 230w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/bc10c/License_Plate_Recognition_03.webp 460w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/966d8/License_Plate_Recognition_03.webp 920w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/fbb05/License_Plate_Recognition_03.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ac544a551b765fa6dc5d56d496ca5c1/81c8e/License_Plate_Recognition_03.png 230w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/08a84/License_Plate_Recognition_03.png 460w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/c0255/License_Plate_Recognition_03.png 920w", "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/2e367/License_Plate_Recognition_03.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ac544a551b765fa6dc5d56d496ca5c1/c0255/License_Plate_Recognition_03.png",
              "alt": "INSTAR Cloud - License Plate Recognition",
              "title": "INSTAR Cloud - License Plate Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "advanced-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advanced-options",
        "aria-label": "advanced options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Options`}</h3>
    <p>{`Here you can manage for which licence plates you would like to be notified in case of a sighting. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a47dbdeb032697f6d53233614fc53447/84ee5/License_Plate_Recognition_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.521739130434778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAyUlEQVQY03XNP0/DMBAFcH/qbiwM/VAMDO0AgokBiYGStATSyPHZ53PO/xNEKqaKn55uek8nlFLDcI4xIiIR/V5rfcoqFJsqWgKljDFEJKVERG1MKWVeiYn567tvmrY/D+SmnHMIgZk9c4xx+V+tVaCG/vPUHZvu2DJh8F7KMV6ktCzLvFYNkibPqa6ZXawpF9GB27+ddq/N/cv788dgOaDB6z9yHAFtyuWSkHLORdy102YP2yd9+6hvHuCg2Ds7X40BwDmqf8rqB/Z7HIppmxhaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a47dbdeb032697f6d53233614fc53447/e4706/License_Plate_Recognition_04.avif 230w", "/en/static/a47dbdeb032697f6d53233614fc53447/d1af7/License_Plate_Recognition_04.avif 460w", "/en/static/a47dbdeb032697f6d53233614fc53447/7f308/License_Plate_Recognition_04.avif 920w", "/en/static/a47dbdeb032697f6d53233614fc53447/85f86/License_Plate_Recognition_04.avif 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a47dbdeb032697f6d53233614fc53447/a0b58/License_Plate_Recognition_04.webp 230w", "/en/static/a47dbdeb032697f6d53233614fc53447/bc10c/License_Plate_Recognition_04.webp 460w", "/en/static/a47dbdeb032697f6d53233614fc53447/966d8/License_Plate_Recognition_04.webp 920w", "/en/static/a47dbdeb032697f6d53233614fc53447/ceabe/License_Plate_Recognition_04.webp 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a47dbdeb032697f6d53233614fc53447/81c8e/License_Plate_Recognition_04.png 230w", "/en/static/a47dbdeb032697f6d53233614fc53447/08a84/License_Plate_Recognition_04.png 460w", "/en/static/a47dbdeb032697f6d53233614fc53447/c0255/License_Plate_Recognition_04.png 920w", "/en/static/a47dbdeb032697f6d53233614fc53447/84ee5/License_Plate_Recognition_04.png 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a47dbdeb032697f6d53233614fc53447/c0255/License_Plate_Recognition_04.png",
              "alt": "INSTAR Cloud - License Plate Recognition",
              "title": "INSTAR Cloud - License Plate Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      